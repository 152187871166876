﻿Array.prototype.contains = function (obj) {
  /// <summary>Saber se existe no array
  /// </summary>
  var i = this.length;
  while (i--) {
    if (this[i] === obj) {
      return true;
    }
  }
  return false;
}
//adicionar às string a opção de substituir todos
String.prototype.replaceAll = String.prototype.replaceAll || function (needle, replacement) {
  return this.split(needle).join(replacement);
};

//remover a classe de erro quando é alterado o valor do input
$(".input-validation-error").change(function (event) {
  $(event.target).removeClass("input-validation-error");
})

$("input[type=number]").bind('keyup input', function () {
  if ($(event.target).hasClass("input-validation-error")) {
    $(event.target).removeClass("input-validation-error");
  }
})

$(".date-picker.input-validation-error").click(function (event) {
  $(event.target).removeClass("input-validation-error");
})

$(".search-button").click(function (event) {

})

//informacao que o capslock esta ligado quando o campo e password
$('[type=password]').keypress(function (e) {
  var $password = $(this),
    tooltipVisible = $('.tooltip').is(':visible'),
    s = String.fromCharCode(e.which);

  //testar se esta letra maiuscula e se o shift nao esta pressionado
  if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
    if (!tooltipVisible)
      $password.tooltip('show');
  } else {
    if (tooltipVisible)
      $password.tooltip('hide');
  }

  //Remover o aviso se sair do campo password
  $password.blur(function (e) {
    $password.tooltip('hide');
  });
});

function responsiveTop() {
  /// <summary>Colocar site responsivo
  /// </summary>

  var topheader = $("#head-nav").height();
  if (window.innerWidth > 767) {
    $("#cl-wrapper").css({ 'padding-top': ($("#head-nav").height() + 'px') });
  } else {
    $("#cl-wrapper").css({ 'padding-top': ('0px') });
  }
}
window.onresize = function (event) {
  responsiveTop();
};
window.onload = function (event) {
  responsiveTop();
};

//antes de fazer o logout confirmar
$("#logout").click(function (event) {
  event.preventDefault();

  bootbox.confirm({
    //message: "Confirma que pretende encerrar a sua sessão" + "?",
    message: msgLogout + "?",
    buttons: {
      confirm: {
        //label: "Sim",
        label: btnsim,
        className: "btn-primary btn-sm"
      },
      cancel: {
        //label: "Cancelar",
        label: btncancelar,
        className: "btn-sm"
      }
    },
    callback: function (result) {
      if (result) {
        $("#logoutForm").submit();
      }
    }
  });
})
